@import '_functions.scss';

// == NEW, CONFIG BASED SKIN

// Images
$img-path: "/img/";
$icon-path: "#{$img-path}icon/";
$logo-positive: var(--skin-logo-positive);
$logo-positive-mobile: var(--skin-logo-positive-mobile);
$logo-negative: var(--skin-logo-negative);
$logo-negative-mobile: var(--skin-logo-negative-mobile);
$img-hero-webp: var(--skin-img-hero-webp);
$img-hero-fallback: var(--skin-img-hero-fallback);

// Colors
$color-white: #FFFFFF;
$color-black: #000000;
$color-primary: var(--skin-color-primary);
$color-secondary: var(--skin-color-secondary);
$color-body: var(--skin-color-body);
$color-error: var(--skin-color-error);
$color-error-light: #FF5A5F;
$color-warning: #FF9A5F;
$color-success: var(--skin-color-success);
$color-success-light: #3AB870;

// Background colors
$background-form: var(--skin-border-color);
$background-success-light: #D7F1E2;
$background-error-light: #FFDEDF;
$background-warning: #FFEBDF;
$background-darkgray: #2D3446; 

// Border
$border-color: var(--skin-border-color);

// Font family
$font-family-primary: var(--skin-font-family-primary);
$font-family-secondary: var(--skin-font-family-secondary);

// Font font-weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

// Font size
$font-size-default-px: var(--skin-font-size-body);
$font-size-default: px2em($font-size-default-px);
$line-height-default: var(--skin-font-line-height-body);





// == OLD, BEFORE CONFIG BASED SKIN (FALLBACK)


// Colors
$black: $color-black;
$white: $color-white;
$primary-color: $color-primary;
$mk-color: #e83479;
$facebook-color: #4267B2;
$gray-dark: $color-primary;
$text-gray: $gray-dark;
$text-gray-light: #8898AA;
$text-gray-inactive: #C8D2DD;
$border-color-light: $text-gray-inactive;
$border-color-ligher: #E4EBF3;
$border-color-inactive: $text-gray-inactive;
$section-separator-color: #EFF4FB;
$danger-color: $color-error;
$success-color: $color-success;
$text-green-special: #55D9C2;
$text-form-selected: #333333;
$btn-default: $border-color-light;
$btn-dark: $gray-dark;
$btn-primary: $primary-color;
$slider-handle-background: $section-separator-color;
$checkbox-toggle-background: $border-color-light;
$active-filter-background: #2D34461A;
$tag-background: $gray-dark;
$tag-light-background: $active-filter-background;
$background-gray-light: #F6F9FC;
$background-gray-lighter: #FAFCFD;
$background-gray: $background-gray-light; // ???
$table-th-background: $background-gray-light;
$input-background-color: $white;

// Font font family
$font-family-default: $font-family-primary;
$font-family-roboto: $font-family-secondary;
