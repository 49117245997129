@import '../vendors/flex.scss';

@mixin active-filter() {
	border-color: $text-gray!important;
	background-color: $active-filter-background;
	font-weight: $font-medium!important;
	color: $gray-dark!important;

	i {
		@include opacity(1);
	}
}

@mixin scrollbar() {
	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: $border-color;
		@include border-radius(4px);
		cursor: pointer;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #333;
	}
}

@mixin mobile-navigation-fixed() {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	height: calc(var(--vh, 1vh) * 100);
	background-color: $white;
	overflow: auto;
	z-index: 102;
	padding: 20px;
	margin-top: 0;
}

@mixin scrollbar() {
	/* width */
	&::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: $background-gray-light;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $text-gray-inactive;
		cursor: pointer;
		@include border-radius(5px);
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	section {
		padding-right: 10px;
	}
}

@mixin dropdown-modal($right: auto) {
	display: none;
	position: absolute!important;
	width: 100%;
	width: 230px;
	background-color: $white;
	@include border-radius(5px);
	box-shadow: 0px 3px 20px #00000029;
	margin-top: 0;
	padding: 20px 0;
	right: $right;
	z-index: 15;

	.separator {
		height: 1px;
		background-color: $section-separator-color;
		width: 100%;
		margin: 20px 0;
	}

	button, a {
		display: block;
		font-weight: $font-medium;
		font-size: 14px;
		text-align: left;
		margin-right: 0;
		padding: 0px 20px;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		i {
			margin-right: 20px;
		}
	}
}

@mixin h1() {
	font-size: 50px;
	line-height: 61px;

	@include media-below(sm) {
		font-size: 36px;
		line-height: 1.3;
	}

	@include media-below(xs) {
		font-size: 30px;
	}
}

@mixin h2() {
	font-size: 30px;
  	line-height: 40px;

	@include media-below(xs) {
		font-size: 20px;
	}
}

@mixin h3() {
	font-size: 20px;
	line-height: 30px;
}

@mixin h4() {
	font-size: 17px;
	line-height: 20px;
}

@mixin h5() {
	font-size: 14px;
	line-height: 30px;
}

@mixin icon($filename, $width, $height) {
	background-image: url('#{$icon-path}#{$filename}');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: $width + px;
	height: $height + px;
	min-width: $width + px;
	min-height: $height + px;
}

@mixin background-image-only($filepath) {
	// background-image: url('#{$img-path}#{$filepath}');
	background-image: $filepath;
}

@mixin icon-dimension-only($width, $height) {
	width: $width + px;
	height: $height + px;
	min-width: $width + px;
	min-height: $height + px;
}

@mixin background-image($filename, $width: 0, $height: 0) {
	@include background-image-only($filename);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@if $width != 0 {
		width: $width + px;
	}

	@if $height != 0 {
		height: $height + px;
	}
}

@mixin icon-size-only($width, $height) {
	width: $width + px;
	height: $height + px;
	min-width: $width + px;
	min-height: $height + px;
}

@mixin icon-image-only($filename, $page: false) {
	background-image: url('#{$icon-path}#{$filename}');
}

@mixin placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees + deg);
          transform: rotate($degrees + deg);
}

@mixin box-shadow($left, $top, $radius, $color) {
	box-shadow: $left $top $radius $color;
	-webkit-box-shadow: $left $top $radius $color;
	-moz-box-shadow: $left $top $radius $color;
}

@mixin transition($property, $duration, $easing: ease-in-out) {
	transition: $property $duration $easing;
	-webkit-transition: $property $duration $easing;
	-moz-transition: $property $duration $easing;
}

@mixin border-radius($radius) {
	border-radius: $radius !important;
	-webkit-border-radius: $radius !important;
	-moz-border-radius: $radius !important;
}

@mixin opacity($value){
	$IEValue: $value*100;
	opacity: $value;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
	filter: alpha(opacity=$IEValue);
}

@mixin animation($value) {
	-webkit-animation: $value;
	-moz-animation: $value;
	-ms-animation: $value;
	-o-animation: $value;
	animation: $value;
}

@mixin backdrop-filter($blur) {
	backdrop-filter: blur($blur);
	-webkit-backdrop-filter: blur($blur);
}

@mixin phone() {
	@media (max-width: 767px) {
    @content;
  }
}

// Media queries

$breakpoints: (
	xxxxs: 321px,
	xxxs: 375px,
	xxs: 480px,
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg-mb: 1279px, // media below (tablet landscape)
	lg: 1281px,
	xl: 1441px,
	xxl: 1600px
);

@mixin media-above($breakpoint) {

	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {

		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Write the media query.
		@media (min-width: $breakpoint-value) {
			@content;
		}

	// If the breakpoint doesn't exist in the map.
	} @else {

		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

@mixin media-above-distinct($width) {
	@media (min-width: $width) {
		@content;
	}
}

@mixin media-below($breakpoint) {

	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {

		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Write the media query.
		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}

	// If the breakpoint doesn't exist in the map.
	} @else {

		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

@mixin media-below-distinct($width) {
	@media (max-width: $width) {
		@content;
	}
}

@mixin media-between($lower, $upper) {

	// If both the lower and upper breakpoints exist in the map.
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

		// Get the lower and upper breakpoints.
		$lower-breakpoint: map-get($breakpoints, $lower);
		$upper-breakpoint: map-get($breakpoints, $upper);

		// Write the media query.
		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}

	// If one or both of the breakpoints don't exist.
	} @else {

		// If lower breakpoint is invalid.
		@if (map-has-key($breakpoints, $lower) == false) {

			// Log a warning.
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}

		// If upper breakpoint is invalid.
		@if (map-has-key($breakpoints, $upper) == false) {

			// Log a warning.
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}

@mixin media-between-distinct($width1, $width2) {
	@media (min-width: $width1) and (max-width: $width2) {
		@content;
	}
}
