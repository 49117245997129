@import '../../styles/abstract.scss';

.default {
	padding: 3px 5px;
	font-weight: $font-semibold;
	@include border-radius(3px);
	margin-left: 5px;
	font-style: normal;
	font-size: 10px;
	line-height: 100%;

	&.standalone {
		margin: 0;
	}

	&.rounded {
		$tag-width: 18px;
		$tag-line-height: 18px;
		padding: 0;

		@include border-radius(50%);
		text-align: center;
		width: $tag-width;
		height: $tag-width;
		min-width: $tag-width;
		min-height: $tag-width;
		line-height: $tag-line-height;
		font-size: 10px;

		/*
		width: 22px;
		height: 22px;
		line-height: 18px;
		font-size: 12px;
		*/
		font-weight: $font-medium;
	}

	&.dark {
		background-color: $gray-dark;
		color: $white;
	}

	&.light {
		background-color: $tag-light-background;
		color: $gray-dark;
	}

	&.primary {
		background-color: $primary-color;
		color: $white;
	}

	&.filter {
		// border: 1px solid $border-color-light;
		padding: 4px 12px;
		margin: 0 5px 5px 0;
		display: inline-block;
		background-color: $tag-light-background;
		color: $gray-dark;
		font-style: normal;
		font-weight: $font-regular;
		@include border-radius(15px);
		font-size: 12px;
		line-height: 15px;

		button {
			margin-left: 7px;
			color: $gray-dark;
			min-height: 0;
		}

		&:not(:last-child) {
			margin-right: 5px;
		}
	}

	a {
		color: inherit;
	}
}
