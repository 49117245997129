@import '../../styles/abstract.scss';

.default {
	position: relative;
	@include flexbox();
	@include align-items(center);

	@include media-below(md) {
		@include flex-direction(column);
	}

	.bgCloseTrigger {
		position: fixed;
		z-index: 1;
		background-color: transparent;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	.dropdown {
		border: 1px solid $border-color-ligher;
		@include border-radius(5px);
		// padding: 8.5px 16px;
		padding-bottom: 0;
		cursor: pointer;

		> button {
			@include flexbox();
			@include align-items(center);
			font-weight: $font-semibold;
			padding: 8.5px 16px;

			> i {
				margin-left: 5px;
			}
		}
	}

	.separator {
		padding-bottom: 0;

		+ .groupTitle {
			padding: 0 20px;
			margin-bottom: 16px;
			color: $text-gray-light;
			font-size: 10px;
			line-height: 13px;
			font-weight: $font-medium;
		}
	}

	.close {
		position: absolute;
		top: 30px;
		width: 17px!important;
		right: 20px;
		width: auto;
		padding: 0;
		margin: 0;
	}

	> a {
		margin-right: 40px;
		font-weight: $font-semibold;
		position: relative;

		[class*="tag_rounded"] {
			line-height: 18px;
		}

		@include media-below(lg) {
			margin-right: 20px;
		}

		@include media-below(md) {
			color: $gray-dark;
			font-size: 17px;
			font-weight: 600;
			margin: 6px 0;
			padding: 6px 0;
		}

		@include media-below(sm) {
			margin-right: 0;
			margin: 5px 0;
		}

		&[class*="lessMargin"] {
			margin-right: 15px;
		}
	}

	> span {
		margin-right: 15px;
	}

	> * {
		color: inherit;
		font-size: 14px;
		position: relative;
		@include flexbox();

		&[class*="danger"] {
			color: $danger-color;
		}

		&:hover {
			text-decoration: none;
		}

		[class*="tag"] {
			width: 20px;
			height: 20px;
			line-height: 20px;
			font-size: 10px;
			display: inline-block;
			padding: 0;
			margin-left: 10px;
		}
	}

	> div {
		position: relative;
		padding-bottom: 5px;
		top: 2.5px;

		> nav {
			@include dropdown-modal();
			color: $text-gray;
			margin-top: 35px;
			right: 0;

			.plainText {
				padding: 0 20px;

				&[class*="smaller"] {
					font-size: 12px;
					color: $text-gray-light;
				}
			}
		}

		/*
		&:hover {
			> nav {
				display: block;
			}
		}
		*/

		&.open {
			> nav {
				display: block;
			}
		}
	}
}
