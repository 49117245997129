@import '../../styles/abstract.scss';

.default {
	position: fixed;
	background-color: rgba(0,0,0,0.6);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	z-index: 1000;
	display: none;

	&.visible {
		@include flexbox();
	}

	.aligned {
		background-color: $white;
		@include border-radius(5px);
		padding: 20px;
		position: relative;

		@include media-below(sm) {
			width: 95%;
		}

		button[class*="close"] {
			position: absolute;
			right: 20px;
			top: 20px;
		}
	}
}
